import React, { useEffect, useContext, useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { getDistance, convertDistance, getCompassDirection } from "geolib";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { withPreview } from "gatsby-source-prismic";
import { createBreakpoint } from "react-use";

// SEO
import { SEO } from "../components/seo/seo";

// Context
import { PageTypeContext } from "../components/context/page-type";
import { ActiveListingCity } from "../components/context/active-city";
import { ActiveListingCountry } from "../components/context/active-country";

// Components
import { AllListingsInCityMap } from "../components/listing/city-listing-map";
import { ListingsAvailableSoon } from "../components/listing/listings-available-soon";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

// Utils
import { dynamicSort } from "../components/utils/dynamic-sort";

// Layout
import {
  Listing,
  SortContainer,
} from "../components/listing/listing-components";

// Containers
import { MasonryContainer } from "../components/containers/masonry-container";

// Setup dayjs
dayjs.extend(relativeTime);

const Page = styled.div`
  padding: 0 90px;

  @media (max-width: 1200px) {
    padding: 0 60px;
  }

  @media (max-width: 1000px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Heading = styled.div`
  margin: 30px 0 114px 0;

  & h1 {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.05em;

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
      letter-spacing: 0.05em;
    }
  }

  @media (max-width: 768px) {
    margin: 30px 0 20px 0;
  }
`;

const FurtherAwayListingsContainer = styled.div`
  background: linear-gradient(
    180deg,
    #f9f9f9 0%,
    rgba(249, 249, 249, 0.796875) 51.54%,
    rgba(249, 249, 249, 0) 100%
  );

  margin: 0 0 80px 0;

  & .section-heading {
    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.05em;

    margin: 0 0 100px 0;

    @media (max-width: 768px) {
      font-size: 17px;
      line-height: 21px;
      letter-spacing: 0.05em;

      margin: 0 0 55px 0;
    }
  }

  padding: 40px 90px 0 90px;

  @media (max-width: 1200px) {
    padding: 40px 60px 0 60px;
  }

  @media (max-width: 1000px) {
    padding: 40px 40px 0 40px;
  }

  @media (max-width: 768px) {
    padding: 40px 20px 0 20px;
  }
`;

const CountryLink = styled.h3`
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.05em;

  & a {
    text-decoration: none;
    border-bottom: 2px solid rgb(14 53 99);

    &:hover {
      border-bottom: 2px solid rgb(14 53 99);
    }
  }

  @media (max-width: 768px) {
    font-size: 25px;
    line-height: 31px;
    letter-spacing: 0.05em;

    margin: 25px 0 0 0;
  }
`;

const Line = styled.hr`
  height: 1px;

  background-image: linear-gradient(
    to right,
    #0e3563 33%,
    rgba(14, 53, 99, 0) 0%
  );

  background-position: bottom;
  background-size: 3px 1px;
  background-repeat: repeat-x;

  margin: 25px 0 0 0;
  padding: 0;
  border: 0;
`;

const useBreakpoint = createBreakpoint({ M: 601, S: 10 });

const City = ({ data }) => {
  const [activeSort, setActiveSort] = useState("alphabetical");
  const [activeMarker, setActiveMarker] = useState(null);
  const [pageTypeContext, setPageTypeContext] = useContext(PageTypeContext);
  const [activeCity, setActiveCity] = useContext(ActiveListingCity);
  const [activeCountry, setActiveCountry] = useContext(ActiveListingCountry);
  const breakpoint = useBreakpoint();

  useEffect(() => {
    setPageTypeContext(`city`);
    setActiveCity({
      name: data.prismicCity.data.name.text,
      url: data.prismicCity.url,
    });
    setActiveCountry({
      name: data.prismicCity.data.country.document.data.name.text,
      url: `/${data.prismicCity.data.country.document.uid}`,
    });
  }, []);

  const origin = data.prismicCity.data.city_coordinates;

  const content = data.allPrismicListing.edges
    .map(content => {
      return {
        ...content,
        distance: getDistance(origin, content.node.data.location),
      };
    })
    .sort(dynamicSort(activeSort))
    .map((content, index) => {
      return (
        <Listing key={`homepage_listing_${content.node.id}`}>
          <Link to={content.node.url}>
            {content.node.data.thumbnail.fluid !== null && (
              <AspectRatioImageContainer image={content.node.data.thumbnail}>
                <img
                  src={content.node.data.thumbnail.fluid.srcWebp}
                  srcSet={content.node.data.thumbnail.fluid.srcSetWebp}
                  alt={content.node.data.thumbnail.alt}
                  loading="lazy"
                />
              </AspectRatioImageContainer>
            )}
          </Link>
          <div className="listing-title">
            <p className="transcript">
              <Link to={content.node.url}>{content.node.data.title.text}</Link>
            </p>
            {(activeSort === `price` || activeSort === `-price`) && (
              <p className="crimson">
                <em>
                  {content.node.data.price_text}{" "}
                  {new Intl.NumberFormat("en-GB", {
                    style: "currency",
                    currency:
                      data.prismicCity.data.country.document.data.currency_code,
                    maximumSignificantDigits: Math.trunc(
                      Math.abs(content.node.data.single_night_price)
                    ).toFixed().length,
                  }).format(content.node.data.single_night_price)}
                  /night
                </em>
              </p>
            )}
            {(activeSort === `sleeps` || activeSort === `sleeps`) && (
              <p className="crimson">
                <em>Sleeps {content.node.data.sleeps}</em>
              </p>
            )}
            {(activeSort === `distance` || activeSort === `-distance`) && (
              <p className="crimson">
                <em>
                  {convertDistance(content.distance, "km").toFixed()}km{" "}
                  {getCompassDirection(origin, content.node.data.location)}
                </em>
              </p>
            )}
          </div>
        </Listing>
      );
    });

  const furtherAwayListing = data.prismicCity.data.further_away_listings
    .filter(content => content.listing.document !== null)
    .filter((content, index) => index <= 2)
    .map((content, index) => (
      <Listing key={`homepage_listing_${content.listing.document.id}`}>
        <Link to={content.listing.document.url}>
          <AspectRatioImageContainer
            image={content.listing.document.data.thumbnail}
          >
            <img
              src={content.listing.document.data.thumbnail.fluid.srcWebp}
              srcSet={content.listing.document.data.thumbnail.fluid.srcSetWebp}
              alt={content.listing.document.data.thumbnail.alt}
              loading="lazy"
            />
          </AspectRatioImageContainer>
        </Link>
        <div className="listing-title">
          <p className="transcript">
            <Link to={content.listing.document.url}>
              {content.listing.document.data.title.text}
            </Link>
          </p>
        </div>
      </Listing>
    ));

  return (
    <>
      <SEO
        seoTitle={data.prismicCity.data.name.text}
        seoText={`All places to stay near ${data.prismicCity.data.name.text}`}
        seoImage={``}
      />

      <ListingsAvailableSoon listings={data.allPrismicListing.edges} />

      <Page>
        <Heading>
          <Flex>
            <h1 className="transcript">
              All places to stay near {data.prismicCity.data.name.text}
            </h1>

            <SortContainer className="transcript">
              <p className="sort-title">Sort:</p>
              <div className="sort-buttons">
                <button
                  className={
                    activeSort === `alphabetical` ||
                    activeSort === `-alphabetical`
                      ? `active-sort`
                      : ``
                  }
                  onClick={() =>
                    setActiveSort(
                      activeSort === "alphabetical"
                        ? "-alphabetical"
                        : "alphabetical"
                    )
                  }
                >
                  A-Z
                </button>

                <button
                  className={
                    activeSort === `distance` || activeSort === `-distance`
                      ? `active-sort`
                      : ``
                  }
                  onClick={() =>
                    setActiveSort(
                      activeSort === "distance" ? "-distance" : "distance"
                    )
                  }
                >
                  Distance
                </button>

                <button
                  className={
                    activeSort === `price` || activeSort === `-price`
                      ? `active-sort`
                      : ``
                  }
                  onClick={() =>
                    setActiveSort(activeSort === "price" ? "-price" : "price")
                  }
                >
                  Price
                </button>
                <button
                  className={
                    activeSort === `sleeps` || activeSort === `sleeps`
                      ? `active-sort`
                      : ``
                  }
                  onClick={() =>
                    setActiveSort(
                      activeSort === "sleeps" ? "-sleeps" : "sleeps"
                    )
                  }
                >
                  Sleeps
                </button>
              </div>
            </SortContainer>
          </Flex>
        </Heading>

        <MasonryContainer>{content}</MasonryContainer>

        {data.allPrismicListing.edges.length >= 1 && (
          <AllListingsInCityMap
            locations={data.allPrismicListing.edges}
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
            cityLocation={data.prismicCity.data.city_coordinates}
            breakpoint={breakpoint}
          />
        )}
      </Page>

      <FurtherAwayListingsContainer>
        {furtherAwayListing.length >= 1 && (
          <>
            <h2 className="transcript section-heading">Further away</h2>
            <MasonryContainer>{furtherAwayListing}</MasonryContainer>
          </>
        )}

        <CountryLink className="transcript">
          View more places in{" "}
          <Link to={`/${data.prismicCity.data.country.document.uid}`}>
            {data.prismicCity.data.country.document.data.name.text}
          </Link>
        </CountryLink>
        <Line />
      </FurtherAwayListingsContainer>
    </>
  );
};

export default withPreview(City);

export const query = graphql`
  query City($uid: String!) {
    prismicCity(uid: { eq: $uid }) {
      url
      data {
        name {
          text
        }
        city_coordinates {
          longitude
          latitude
        }
        country {
          document {
            ... on PrismicCountry {
              id
              uid
              data {
                name {
                  text
                }
                currency_code
              }
            }
          }
        }
        further_away_listings {
          listing {
            document {
              ... on PrismicListing {
                id
                url
                data {
                  thumbnail {
                    fluid {
                      srcSetWebp
                      srcWebp
                      aspectRatio
                    }
                    dimensions {
                      width
                      height
                    }
                  }
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicListing(
      sort: { fields: data___title___text, order: ASC }
      filter: { data: { city: { uid: { eq: $uid } } } }
    ) {
      edges {
        node {
          id
          url
          data {
            title {
              text
            }
            sleeps
            size {
              text
            }
            price_text
            single_night_price
            start_date
            end_date
            price
            travel_time_from_city {
              text
            }
            thumbnail {
              alt
              dimensions {
                height
                width
              }
              fluid {
                srcWebp
                srcSetWebp
                aspectRatio
              }
            }
            location {
              longitude
              latitude
            }
            city {
              document {
                ... on PrismicCity {
                  id
                  url
                  data {
                    name {
                      text
                    }
                  }
                }
              }
            }
            fallback_city
            country {
              document {
                ... on PrismicCountry {
                  id
                  url
                  data {
                    name {
                      text
                    }
                    currency_code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
