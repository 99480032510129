import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import ReactMapGL, { Marker, Popup, WebMercatorViewport } from "react-map-gl";

// Components
import { Navigation } from "../maps/navigation";
import { MarkerIcon } from "../maps/marker-icon";
import { LinkIcon } from "../icons/link-arrow";

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 500px;

  margin: 0 0 80px 0;

  @media (max-width: 768px) {
    margin: 15px 0 60px 0;
  }

  @media (max-width: 600px) {
    height: 350px;
  }

  & .mapboxgl-popup-tip {
    border: 0;
  }

  & .mapboxgl-popup-content {
    background: #0e3563;
    margin: 0 0 0 20px;

    padding: 5px 8px 2px 8px;
    box-shadow: none;
    border-radius: 0;
  }

  & .mapboxgl-popup-close-button {
    display: none;
  }

  & a {
    text-decoration: none !important;
    border-bottom: 0 !important;

    &:hover {
      border-bottom: 0 !important;
    }
  }
`;

const PopupContainer = styled.div`
  color: #fff;

  & p {
    margin: 0;

    font-size: 20px;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 15px;
    }
  }

  & a {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: baseline;
  }

  & svg {
    margin: 0 0 0 15px;

    width: 14px;
    height: auto;
  }
`;

const applyToArray = (func, array) => func.apply(Math, array);

const DesktopMap = ({
  activeMarker,
  allMapLocations,
  locations,
  cityLocation,
}) => {
  const getBoundsForPoints = points => {
    // Calculate corner values of bounds
    const pointsLong = points.map(point => point.longitude);
    const pointsLat = points.map(point => point.latitude);
    const cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 800,
      height: 500,
    }).fitBounds(cornersLongLat, { padding: 50 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  };

  let mapPoints = locations.map((listing, index) => {
    return {
      longitude: listing.node.data.location.longitude,
      latitude: listing.node.data.location.latitude,
    };
  });

  const origin = {
    latitude: cityLocation.latitude,
    longitude: cityLocation.longitude,
  };
  mapPoints.push(origin);

  const bounds = getBoundsForPoints(mapPoints);
  const [viewport, setViewport] = useState({
    ...bounds,
  });

  return (
    <MapContainer>
      {/* //https://medium.com/@weberzt/creating-a-multi-marker-map-using-mapbox-gl-js-105224ba3627 */}
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={nextViewport => setViewport(nextViewport)}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX}
        mapStyle="mapbox://styles/covers/ckhyryqg4237n19qu0nsriw0c"
        scrollZoom={false}
        maxZoom={15}
        minZoom={4}
      >
        <Navigation />
        {allMapLocations}

        {activeMarker !== null && (
          <Popup
            latitude={activeMarker.data.location.latitude}
            longitude={activeMarker.data.location.longitude}
            anchor="left"
          >
            <PopupContainer>
              <p className="transcript">
                <Link to={activeMarker.url}>
                  {activeMarker.data.title.text}
                  <LinkIcon fill={`#fff`} />
                </Link>
              </p>
            </PopupContainer>
          </Popup>
        )}
      </ReactMapGL>
    </MapContainer>
  );
};

const MobileMap = ({
  activeMarker,
  allMapLocations,
  locations,
  cityLocation,
}) => {
  const getBoundsForPoints = points => {
    // Calculate corner values of bounds
    const pointsLong = points.map(point => point.longitude);
    const pointsLat = points.map(point => point.latitude);
    const cornersLongLat = [
      [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
      [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)],
    ];
    // Use WebMercatorViewport to get center longitude/latitude and zoom
    const viewport = new WebMercatorViewport({
      width: 320,
      height: 350,
    }).fitBounds(cornersLongLat, { padding: 50 }); // Can also use option: offset: [0, -100]
    const { longitude, latitude, zoom } = viewport;
    return { longitude, latitude, zoom };
  };

  let mapPoints = locations.map((listing, index) => {
    return {
      longitude: listing.node.data.location.longitude,
      latitude: listing.node.data.location.latitude,
    };
  });

  const origin = {
    latitude: cityLocation.latitude,
    longitude: cityLocation.longitude,
  };
  mapPoints.push(origin);

  const bounds = getBoundsForPoints(mapPoints);
  const [viewport, setViewport] = useState({
    ...bounds,
  });

  return (
    <MapContainer>
      {/* //https://medium.com/@weberzt/creating-a-multi-marker-map-using-mapbox-gl-js-105224ba3627 */}
      <ReactMapGL
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={nextViewport => setViewport(nextViewport)}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX}
        mapStyle="mapbox://styles/covers/ckhyryqg4237n19qu0nsriw0c"
        scrollZoom={false}
      >
        <Navigation />
        {allMapLocations}

        {activeMarker !== null && (
          <Popup
            latitude={activeMarker.data.location.latitude}
            longitude={activeMarker.data.location.longitude}
            anchor="left"
          >
            <PopupContainer>
              <p className="transcript">
                <Link to={activeMarker.url}>
                  {activeMarker.data.title.text}
                  <LinkIcon fill={`#fff`} />
                </Link>
              </p>
            </PopupContainer>
          </Popup>
        )}
      </ReactMapGL>
    </MapContainer>
  );
};

export const AllListingsInCityMap = ({
  locations,
  cityLocation,
  activeMarker,
  setActiveMarker,
  breakpoint,
}) => {
  const allMapLocations = locations.map((content, index) => (
    <Marker
      key={`favourites_map_marker_${index}`}
      latitude={content.node.data.location.latitude}
      longitude={content.node.data.location.longitude}
    >
      <MarkerIcon
        onClick={() => setActiveMarker(content.node)}
        bgColor={`#0e3563`}
      />
    </Marker>
  ));

  return (
    <>
      {breakpoint === "M" && (
        <DesktopMap
          activeMarker={activeMarker}
          allMapLocations={allMapLocations}
          locations={locations}
          cityLocation={cityLocation}
        />
      )}

      {breakpoint === "S" && (
        <MobileMap
          activeMarker={activeMarker}
          allMapLocations={allMapLocations}
          locations={locations}
          cityLocation={cityLocation}
        />
      )}
    </>
  );
};
